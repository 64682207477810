import { z } from 'zod';

import { ContractMemberDto } from '@ygm/common/core/services/dto/contract-member.dto';
import { campaignUserDtoSchema } from '@ygm/common/core/services/dto/campaign-user.dto';
import { LevelDto } from '@ygm/common/core/services/dto/level.dto';
import { createPaginationDtoSchema } from '@ygm/common/core/services/dto/pagination.dto';
import { ContractTypeDto } from '@ygm/common/core/services/dto/contract-type.dto';

import { LevelInstanceDto, levelInstanceDtoSchema } from './level-instance.dto';
import { baseContractDtoSchema, BaseContractDto } from './base-contract.dto';
import { campaignDtoSchema } from './campaign.dto';
import { ContractPreferredPaymentMethodDto } from './contract-preferred-payment-method.dto';

/** Contract DTO schema. */
export const contractDtoSchema = baseContractDtoSchema.extend({
	/** Campaign data. */
	campaign: campaignDtoSchema,

	/** Levels count. */
	levels_count: z.number(),

	/** Private note. */
	private_note: z.string(),

	/** Total cost. */
	total_cost: z.number(),

	/** Has mergeable contracts. */
	has_mergeable_contracts: z.boolean(),

	/** Share credits with users dto. */
	shared_credits_with: z.array(campaignUserDtoSchema),

	/** Levels. */
	levels: z.array(levelInstanceDtoSchema),

	/** Preferred payment method. */
	preferred_payment_method: z.nativeEnum(ContractPreferredPaymentMethodDto),
});

/** Contract pagination DTO schema. */
export const contractPaginationDtoSchema = createPaginationDtoSchema(contractDtoSchema);

/** Contract DTO. */
export type ContractDto = Readonly<z.infer<typeof contractDtoSchema>>;

/** Contract details DTO schema. */
export const contractDetailsDtoSchema = contractDtoSchema.extend({
	/** Contract's signature. */
	signature: z.string().nullable(),
});

/** Contract details DTO. */
export type ContractDetailsDto = Readonly<z.infer<typeof contractDetailsDtoSchema>>;

/** Shared credit DTO schema. */
export const sharedCreditDtoSchema = z.object({
	/** User campaign ID. */
	id: z.number(),

	/** User campaign. */
	user_campaign: campaignUserDtoSchema,

	/** Amount. */
	amount: z.number(),
});

/** List members DTO. */
export type SharedCreditDto = Readonly<z.infer<typeof sharedCreditDtoSchema>>;

type LevelInstanceEditDto = Pick<LevelInstanceDto, 'id' | 'trade_with' | 'cost'> & {

	/** Level ID. */
	readonly level_id: LevelDto['id'];

	/** Is declined. */
	readonly is_declined: boolean;
};

/** Contract type edit DTO. */
export type ContractTypeEditDto = Pick<
ContractDetailsDto,
'type' | 'status' | 'signature' | 'preferred_payment_method' | 'private_note'
> & {

	/** Levels. */
	readonly levels: readonly LevelInstanceEditDto[];

	/** Shared credits. */
	readonly shared_credits: readonly {

		/** User campaign ID. */
		readonly user_campaign_id: number;

		/** Amount. */
		readonly amount: number;
	}[];

	/** Member. */
	readonly member: ContractMemberDto;
};

/** Contract creation DTO. */
export type ContractCreationDto = Pick<
ContractDetailsDto,
'type' | 'status' | 'signature' | 'preferred_payment_method' | 'private_note'
> & {

	/** Created by id. */
	readonly created_by_id: number;

	/** Contract type. */
	readonly type: ContractTypeDto;

	/** Levels. */
	readonly levels: readonly LevelInstanceEditDto[];

	/** Shared credits. */
	readonly shared_credits: readonly {

		/** User campaign ID. */
		readonly user_campaign_id: number;

		/** Amount. */
		readonly amount: number;
	}[];

	/** Member. */
	readonly member: {

		/** Stored member ID. */
		readonly stored_member_id: number | null;

		/** Name. */
		readonly name: string;

		/** Address. */
		readonly address: string;

		/** City. */
		readonly city: string;

		/** State. */
		readonly state: string;

		/** Zipcode. */
		readonly zipcode: string;

		/** First name. */
		readonly first_name: string;

		/** Last name. */
		readonly last_name: string;

		/** Email. */
		readonly email: string;

		/** Work phone. */
		readonly work_phone: string;

		/** Mobile phone. */
		readonly mobile_phone: string;
	};

	/** Note. */
	readonly note: string;

	/** Created date. */
	readonly created_date: string;
};

/** Contract merge DTO. */
export type ContractMergeDto = {

	/** The original contract Id with which the sources contracts will be merged. */
	readonly dest_contract_id: BaseContractDto['id'];

	/** Sources contracts Ids that will be merged with the original contract. */
	readonly source_contract_ids: readonly BaseContractDto['id'][];
};
